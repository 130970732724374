<template>
  <v-progress-linear
    v-if="isLoading"
    color="primary"
    indeterminate
  ></v-progress-linear>

  <v-container
    v-else
    class="my-12"
  >
    <div class="text-center">
      <h1 class="text-h4">Sections Sensibles</h1>

      <h2 class="text-subtitle-1">
        Attention, une seule inscription est autorisée pour l'ensemble des cursus.
      </h2>
    </div>

    <div class="mt-6">
      <cursus-closed v-if="!formIsOpen"></cursus-closed>

      <para-form
        v-else
        :cursus="cursus"
      ></para-form>
    </div>
  </v-container>
</template>

<script>
import CursusClosed from '@/components/guest/CursusClosed.vue';
import ParaForm from '@/components/guest/ParaForm.vue';

export default {
  name: 'Para',

  components: {
    CursusClosed,
    ParaForm,
  },

  data: () => ({
    isLoading: true,
    cursus: [],
    formIsOpen: true,
  }),

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const response = await this.$http.get('guest/getData.php?type=para');
        this.cursus = response.data;
      } catch (error) {
        if ([403, 404].includes(error.response?.status)) this.formIsOpen = false;
        else this.$router.push({ name: 'ErrorUnexpected' });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
